import React from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from "framer-motion";

const Navigation = ({location, animSettings}) => {
    const { pathname } = location;

    if(pathname === "/" || pathname === "/about" || pathname === "/contact" ) {
        return (
            <AnimatePresence>
                <motion.div {...animSettings}>
                    <nav id="main-nav" className="large-font top-layer">
                        <ul>
                            <li><Link 
                                id="reel-link"
                                to="/reel" 
                                className={pathname === '/reel' ? 'nav-active' : ''}
                                >
                                    Reel</Link></li>
                            <li><Link
                                to={pathname === '/about' ? '/' : '/about'}
                                className={pathname === '/about' ? 'nav-active' : ''}
                                >
                                    About</Link></li>
                            <li><Link 
                                to={pathname === '/contact' ? '/' : '/contact'}
                                className={pathname === '/contact' ? 'nav-active' : ''}
                                >
                                    Contact</Link></li>
                        </ul>
                    </nav>
                </motion.div>
            </AnimatePresence>
    )
    } else {
        return <AnimatePresence></AnimatePresence>
    }
};

export default Navigation;