import React from 'react';
import { motion } from "framer-motion"

const Contact = ({animSettings}) => {
    const { initial, animate, exit, transition } = animSettings;

    return (
        <motion.div key="contact" initial={initial} animate={animate} exit={exit} transition={transition}>
            <div id="contact-section" className="subpage mid-layer">
                <p className="section-title-main technical-font">Contact</p>
                <p className="subpage-text-large">You can contact me via e-mail or Instagram:</p>
                <p className="subpage-text-large"><a data-no-swup href="mailto:contact@alexmajewski.com">contact@alexmajewski.com</a></p>
                {/* <p className="subpage-text-body">
                    I'll be happy to hear about your project.</p> */}
            </div>
        </motion.div>
    )
};

export default Contact;