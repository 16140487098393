import React from 'react';
import { AnimatePresence, motion } from "framer-motion";

const Herovideo = ({location, animSettings}) => {
    const { initial, animate, exit, transition } = animSettings;
    const { pathname } = location;

    if(pathname === "/" || pathname === "/about" || pathname === "/contact" ) {
        return (
            <AnimatePresence>
                <motion.div {...animSettings}>
                        <video 
                            id="herovideo" 
                            src="/video/Hostile_Habitat_16x9_compressed.mp4" 
                            loop 
                            muted 
                            autoPlay
                            playsInline>
                        </video>
                </motion.div>
            </AnimatePresence>
    )
    } else {
        return <AnimatePresence></AnimatePresence>
    }
};

export default Herovideo;