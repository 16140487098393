import React from 'react';
import { Link } from 'react-router-dom';

const Logo = () => {

    return (
             <Link to="/" id="name-at-the-top" className="top-layer home-page-font">Alex Majewski</Link>
    )
};

export default Logo;