import React, { useState } from 'react';
import './App.scss';
import { Routes, Route, Link, useLocation } from 'react-router-dom';

import Reel from './components/pages/reel';
import About from './components/pages/about';
import Contact from './components/pages/contact';

import { AnimatePresence } from "framer-motion";

import Logo from './components/elements/logo';
import Navigation from './components/elements/navigation';
import Herovideo from './components/elements/herovideo';
import BlackOverlay from './components/elements/blackoverlay';


function App() {
  const location = useLocation();

  const framerDefaults = {
    initial: { opacity: 0 },
    animate: { opacity: 1},
    exit: { opacity:0, transition: {duration: 0.5} },
    transition: { duration: 1 }
  }
  const framerReel = {
    initial: framerDefaults.initial,
    animate: framerDefaults.animate,
    exit: framerDefaults.exit,
    transition: { duration: 1, delay: 0.5}
  }

  return (
        <div>
            <Logo/>
            <Herovideo key="herovideo" location={location} animSettings={framerDefaults}/>
            <Navigation key="navigation" location={location} animSettings={framerDefaults}/>
            <BlackOverlay key="blackoverlay" location={location} animSettings={framerDefaults}/>

            <AnimatePresence>
              <Routes location={location}>
                <Route path="/reel" element={<Reel animSettings={framerReel}/>} />
                <Route path="/about" element={<About animSettings={framerDefaults}/>} />
                <Route path="/contact" element={<Contact animSettings={framerDefaults}/>} />
              </Routes>
            </AnimatePresence>

        </div>
          
  );
}
// 
export default App;
