import React from 'react';
import { motion } from "framer-motion"
import { Link } from 'react-router-dom';

const Reel = ({animSettings}) => {
    const { initial, animate, exit, transition } = animSettings;


    return (
        <motion.div key="reel" initial={initial} animate={animate} exit={exit} transition={transition}>
            <div>
                <Link className="reel-back-button large-font" to="/">Back</Link>
        
                <div className="flex-center">
                    <video id="reel" src="/video/AlexMajewski_Reel-2023-06-optimized.mp4" controls></video>
                </div>
            </div>
        </motion.div>

    )
};

export default Reel;