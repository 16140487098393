import React from 'react';
import { motion } from "framer-motion"

const About = ({animSettings}) => {

    return (
        <motion.div key="about" {...animSettings}>
            <div id="about-section" className="subpage mid-layer">
                    
                <p className="section-title-main technical-font">About</p>
                <div class="two-part-container">

                    <div class="two-part">
                        <p className="subpage-text-large">
                            I'm a technical artist specializing in the creation of high-end animations of products and VFX.
                        </p>
                        <p className="subpage-text-body">Currently I'm employed at <a href="https://www.arsthanea.com/" target="_blank">Ars Thanea</a>.</p>
                        <p id="social-media-links" className="subpage-text-medium"><a href="https://www.instagram.com/xander.majewski/" target="_blank">Instagram</a></p>

                        <div className="skills-list-container">
                            <div className="skills-list">
                                <p className="section-title-minor">Technical info</p>
                                <ul className="technical-font technical-list">
                                    <li>Houdini</li>
                                    <li>Redshift/Octane</li>
                                    <li>Deadline</li>
                                    <li>Python, VEX</li>
                                    <li>Prism, ShotGrid</li>
                                    <li>Substance suite</li>
                                </ul>
                            </div>
                            <div className="skills-list">
                                <p className="section-title-minor">Additional exp.</p>
                                <ul className="technical-font technical-list">
                                    <li>Blender</li>
                                    <li>Unreal Engine</li>
                                    <li>Javascript & Three.js</li>
                                    <li>Marvelous Designer</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="two-part">
                        <img src="/images/portrait.jpg"/>
                    </div>
                </div>
            </div>
        </motion.div>
    )
};

export default About;