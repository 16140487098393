import React from 'react';
import { AnimatePresence, motion } from "framer-motion";

const BlackOverlay = ({location, animSettings}) => {
    const { pathname } = location;

    if(pathname === "/about" || pathname === "/contact" ) {
        return (
            <AnimatePresence>
                <motion.div {...animSettings} className="black-overlay">
                </motion.div>
            </AnimatePresence>
        );
    } else {
        return <AnimatePresence></AnimatePresence>;
    }
    
};

export default BlackOverlay;